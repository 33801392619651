import * as React from "react";
import _ from "lodash";

import Card from "react-bootstrap/Card";
import {Link} from "react-router-dom";

class ShoppingCart extends React.Component {

    getCartDetails = (name) => {
        const {cart} = this.props;

        return cart.getProduct(name);
    }

    qtyUp = (name) => {

        if (!name) {
            return; // Skip empty products
        }

        const cartDetails = this.getCartDetails(name);
        let newQty = cartDetails && cartDetails.qty ? cartDetails.qty + 1 : 1

        const {onQtyChange} = this.props;
        onQtyChange && onQtyChange(name, newQty)
    }

    qtyDown = (name) => {
        if (!name) {
            return; // Skip empty products
        }

        const cartDetails = this.getCartDetails(name);
        let newQty = cartDetails.qty > 0 ? cartDetails.qty - 1 : 0

        const {onQtyChange} = this.props;
        onQtyChange && onQtyChange(name, newQty)
    }

    render() {

        const {cart, sidebar, shouldUseDiscount} = this.props;

        const totalPrice    = cart.getTotal();
        const totalProducts = cart.getTotalItems();
        const totalDiscount = cart.getDiscount();
        const deliveryPrice = cart.getDelivery();
        const cartRows      = cart.getItems();

        const rootClass = [sidebar ? 'd-none d-md-block d-lg-block ' : 'd-block d-md-none'].join(' ')

        return (
            <div className={rootClass}>
                <Card>
                    <Card.Body>
                        <Card.Title>Resumen del orden</Card.Title>

                        <div className="card-content">
                            {cartRows.map((row) => {

                                const specialPrice = shouldUseDiscount && row.special_price
                                    ? row.price - (parseInt(row.base_price) - parseInt(row.special_price))
                                    : null;

                                return (
                                    <div key={`ps-${row.rowId}`} className="product-summary">
                                        <div className="d-flex">
                                            <div className="qty">{row.qty} &times;</div>
                                            <div className="name">
                                                {row.name}
                                                {!row.extras || !row.extras.length ? null :
                                                    <span className="extras-title d-block">
                                                        Extra: {_.map(row.extras, o => o.name) ? _.map(row.extras, o => o.name).join(', ') : ''}
                                                    </span>
                                                }
                                            </div>
                                            <div className="price">
                                                {specialPrice === null || parseInt(specialPrice) === parseInt(row.price) ?
                                                    <span>$ {row.price * row.qty}</span>
                                                    :
                                                    (
                                                    <>
                                                        <span className="old-price">$ {row.price * row.qty}</span>
                                                        <span className="new-price">$ {specialPrice * row.qty}</span>
                                                    </>
                                                    )
                                                }


                                                <div className="add-to-cart disable-dbl-tap-zoom">
                                                    <button className="btn" style={{display: !row || row.qty < 1 ? 'none' : ''}}
                                                            onClick={() => this.qtyDown(row.name)}>
                                                        <i className="fal fa-minus-circle "/>
                                                    </button>

                                                    <button className="btn" onClick={() => this.qtyUp(row.name)}>
                                                        <i className="fal fa-plus-circle "/>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}

                            {!sidebar ? null :
                                <div className="product-summary border-bottom-none">
                                    <div className="d-flex size18 mb-2">
                                        <div className="text-weight-300 flex-grow-1">Productos</div>
                                        <div className="text-weight-300">$ {totalProducts}</div>
                                    </div>
                                    {!totalDiscount ? null :
                                        <>
                                            <div className="d-flex size18 mb-2">
                                                <div className="text-weight-300 flex-grow-1">Discount</div>
                                                <div className="text-weight-300">- $ {totalDiscount}</div>
                                            </div>
                                        </>
                                    }
                                    <div className="d-flex size18 mb-2">
                                        <div className="text-weight-300 flex-grow-1">Entrega</div>
                                        <div className="text-weight-300">$ {deliveryPrice}</div>
                                    </div>
                                    <div className="d-flex size20 mt-4">
                                        <div className="text-weight-500 flex-grow-1">TOTAL</div>
                                        <div className="text-weight-500">$ {totalPrice}</div>
                                    </div>
                                </div>
                            }
                        </div>

                        <div className="mt-3 text-center">
                            <Link to="/new" className="">Cambia el orden</Link>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        )
    }
}

export default ShoppingCart;
