import React, {useState} from 'react';
import {Button, Modal} from 'react-bootstrap';

const SystemOfflineAlert = ({onClose}) => {
  const [show, setShow] = useState(true);

  return(
    <Modal
      show={show}
      centered
      backdrop="static"
      dialogClassName="confirmation-popup"
    >
      <Modal.Body>
        <div className="screen-loading">
          <p className="offline-note">Pedidos en linea no son disponibles en este momento</p>

          <p>Marca <a href="tel:4492514915">449-251-4915</a> para confirmar nuestros horas de operacion.</p>

          <p>Saludos!<br/>Team Campfire</p>

          <Button variant="outline-primary" onClick={() => setShow(false)}>Cerrar</Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default SystemOfflineAlert;
