import * as React from "react";
import _ from "lodash";
import CategoryMenu from "./CategoryMenu";
import ProductRow from "./ProductRow";

import Card from "react-bootstrap/Card";
import {Link} from "react-router-dom";
import CartContext from "../../components/CartContext";
import ApiClient from "../../components/ApiClient";
import Spinner from "react-bootstrap/Spinner";
import AppState from "../../stores/AppState";

class Category extends React.Component {

    getProducts = () => {
        const {data, onQtyChanged, search} = this.props;
        if (!data.products || !data.products.length) {
            return ""
        }

        const products = [];

        for (const productItem of data.products) {
            const product = _.clone(productItem);

            if (-1 !== product.name.toLowerCase().indexOf(search.toLowerCase())) {
                products.push(<ProductRow
                    key={`pr-${product.name}`}
                    data={product}
                    category={data}
                    onQtyChanged={onQtyChanged}  />)
            }
        }

        return products;
    }

    render() {
        const {data} = this.props;

        const products = this.getProducts();

        if (!products || !products.length) {
            return null;
        }

        return (
            <div id={`category-${data.name}`} className={["category-card", data.featured && "featured"].join(' ')}>
                <Card>
                    <Card.Body>
                        <Card.Title className="">
                            {data.featured && <img className="new-label" src="/new-label.png" />}
                            {data.name}
                        </Card.Title>
                        <div className="card-content">
                            {products}
                        </div>

                    </Card.Body>
                </Card>
                <div className="text-center">
                    <img className="mt-4 mb-0" src="images/section-heading-separator-red.png" />
                </div>
            </div>
        )
    }
}

class CartSummaryCta extends React.Component {

    render() {
        const { cart } = this.props;
        const items = cart.getItems();

        let show = '';
        if (items && items.length) {
            show = 'cta-show';
        }

        const totalQty    = _.sumBy(cart.getItems(), (o) => o.qty)
        const totalPrice  = cart.getTotalItems()

        let btn = `Pedido ${totalQty} por $ ${totalPrice}`;
        return (
            <div className={"checkout-cta " + show}>
                <Link to="/checkout" className="btn btn-primary btn-lg btn-cart">{btn}</Link>
            </div>
        )
    }
}

class OrderProductsMenu extends React.Component {

    static contextType = CartContext;

    state = {
        products: [],
        categories: [],
        search: '',
    }

    handleQuantityChange = (name, qty, variant) => {
        const { cart } = this.context;
        let { products } = this.state;

        if (!name) {
            // Only trigger refresh
            this.setState({})

            return;
        }

        const product = _.find(products, {name})
        qty = qty ? qty : 0;

        if (product && product.name) {
            cart.updateQuantity(product, qty, variant);
        }

        // Trigger refresh
        this.setState({})
    }

    getMenu = () => {
        const { search } = this.state;
        const categories  = [...this.state.categories];

        let menu = [];

        for (const category of categories) {
            menu.push(<Category
                key={`catlist-${category.name}`}
                data={category}
                search={search}
                onQtyChanged={this.handleQuantityChange} />);

        }

        return menu;
    }

    async loadMenu() {

        let products = [], categories = [];

        try {
            const res = await ApiClient.get({endpoint: window.API_BASE_URL + '/api/venue/1/order/menu-products'});

            categories = res && res.body && res.body.data ? res.body.data.categories : [];

            // Order categories by the Featured flag
            categories = _.orderBy(categories, (el) => {
                return el.featured ? 1 : 0;
            }, 'desc');

            for (const category of categories) {
                products = _.concat(products, category.products)
            }
        } catch (e) {
            products = [];
            categories = [];
        }

        AppState.set('online_menu', categories);
        this.setState({products, categories})
    }

    componentDidMount() {
        // Load menu from API
        this.loadMenu()
    }

    render() {

        const { search } = this.state;
        const { cart } = this.context;
        const categories  = this.state.categories ? [...this.state.categories] : [];

        const pickupDiscount = parseInt(cart.getSystemConfig('ordering_online_pickup_discount'));

        const lclass = ["menu-loading", categories && categories.length ? "not-show" : ''].join(' ');

        return (
            <div className="order-menu">
                <div className={lclass}>
                    <Spinner animation="border" variant="primary" />
                </div>

                <div className="product-search">
                    <div className="container-lg">
                    <input type="text"
                           value={search}
                           placeholder="Buscar producto"
                           onChange={(ev) => this.setState({search: ev.target.value})}
                           className="form-control form-control-md" />
                    </div>
                </div>

                <CategoryMenu tabs={categories} />

                {pickupDiscount > 0 && (
                    <div className="pickup-discount-text text-center mt-4 mx-3 text-primary">
                        <p className="lead promo-text mb-0">
                            <span>Recoges en Campfire y recibe un descuento de {pickupDiscount}%</span>
                        </p>
                        <p className="help-block" style={{fontStyle: 'italic'}}>No aplican en otros promociones</p>
                    </div>
                )}

                <div className="categories-container">
                    {this.getMenu()}
                </div>

                {cart.systemOnline === false ? null :
                <CartSummaryCta cart={cart}/>
                }
            </div>
        )
    }
}

export default OrderProductsMenu;
