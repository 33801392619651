import * as React from "react";
import _ from "lodash";
import md5 from "md5";
import CartContext from "../../components/CartContext";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ProductRowManage from "./ProductRowManage";
import Collapse from "react-bootstrap/Collapse";

class ProductRow extends React.Component {
    static contextType = CartContext;

    state = {
        addModal: false,
        extrasModal: false,
        variant: '',
        note: '',
        extras: [],
    }

    qtyUpVariant = () => {
        const { data, onQtyChanged } = this.props;
        const { selectedVariant: variant } = this.state;
        const { cart } = this.context;

        const product =  _.clone(data);
        const rowId = md5(product.name + ' - ' + variant.name);

        // Prepare the product price based on the variant price
        product.price = variant.price;

        product.base_price = parseInt(product.price);
        product.parent = product.name;
        product.variant = variant.name;

        product.name   = product.name + ' - ' + variant.name;

        cart.updateQuantityByRowId(rowId, product, "1");

        onQtyChanged && onQtyChanged();

        this.setState({addModal: false})
    }

    qtyUpWithExtras = () => {
        const { data, onQtyChanged } = this.props;
        const { extras }  = this.state;
        const { cart }    = this.context;

        // Serialize extras
        const extrasString  = _.map(extras, o => o.name) ? _.map(extras, o => o.name).join(', ') : '';
        const extrasPrice   = _.reduce(extras, (total, e) => parseInt(total, 10) + parseInt(e.price || 0, 10), 0);

        // Check cart for this product
        const product   =  _.clone(data);
        const rowId     = md5(product.name + (extrasString ? ' ' + extrasString : ''))

        // Prepare data
        product.base_price = parseInt(product.price);
        product.price  = parseInt(product.price, 10) + parseInt(extrasPrice, 10);
        product.extras = extras;
        product.parent = product.name;
        console.log('product', product)
        // Update quantity and reflect in state
        cart.updateQuantityByRowId(rowId, product, "1");
        onQtyChanged && onQtyChanged();

        this.setState({addModal: false, extras: []})
    }

    qtyUp = () => {
        const { data, onQtyChanged } = this.props;
        const { cart } = this.context;

        data.base_price = parseInt(data.price);

        const rowId = md5(data.name);
        cart.updateQuantityByRowId(rowId, data, "1");

        onQtyChanged && onQtyChanged();
    }

    qtyDown = (name, rowId) => {
        const { cart } = this.context;
        const { onQtyChanged } = this.props;

        if (typeof rowId === 'undefined' || !rowId) {
            rowId = md5(name)
        }

        cart.updateQuantityByRowId(rowId, null, "-1");

        onQtyChanged && onQtyChanged();
    }

    changeNote = (value) => {
        const cartDetails = this.getCartDetails();
        if (!cartDetails) {
            return;
        }

        this.setState({note: value}, () => {
            const {onNoteChanged} = this.props;

            onNoteChanged && onNoteChanged(cartDetails.name, this.state.note)
        })
    }

    getCartDetails = (productName) => {
        const { cart } = this.context;

        productName = productName ? productName : this.props.data.name;

        const items = cart.getItems();
        let item = _.find(items, {name: productName})

        if (!item) {
            item = _.find(items, (prod) => {
                if (prod.parent === productName  ) {
                    return true;
                }
                return false;
            })
        }

        return item ? item : null;
    }

    triggerQuantityIncrease = () => {
        const {data, category} = this.props;

        if (data.variants && data.variants.length) {
            // Show a modal and confirm which variant of this product they want
            this.setState({
                addModal: true,
            });
            return;
        }

        /*if (category.extras && category.extras.length) {
            this.setState({
                extrasModal: true,
            });

            return;
        }*/

        this.qtyUp();
    }

    toggleExtraIngredient = (ev, variant) => {
        let {extras} = this.state;

        const exists = _.findIndex(extras, {...variant});
        if (-1 !== exists) {
            _.pullAt(extras, [exists]);
        } else {
            extras.push(variant)
        }

        extras = _.sortBy(extras, 'name')

        this.setState({extras})
    }

    componentDidMount() {
        const cartDetails = this.getCartDetails();


        const state = {};
        if (cartDetails && cartDetails.note) {
            state.note = cartDetails.note;
        }

        if (cartDetails && cartDetails.variant) {
            state.variant = cartDetails.variant;
        }

        this.setState(state)
    }

    render() {
        const { category, data } = this.props;
        const { addModal, extrasModal, note, selectedVariant } = this.state;
        const { cart } = this.context;

        const target = React.createRef();

        const { variants } = data;
        const cartProduct = this.getCartDetails();

        let products = [];
        if (cartProduct) {
            products.push(cartProduct)

            if (cartProduct.parent) {
                const childrens = _.filter(cart.getItems(), {parent: cartProduct.parent})
                if (childrens && childrens.length) {
                    products = childrens;
                }
            }
        }

        // Check if we have in the

        const extrasList = category && category.extras ? category.extras : [];

        return (
            <div className={['product-row'].join(' ')}>
                {data.enabled?null:<div className="row-disabled">No disponible por el momento</div>}
                <div className="product-details">
                    <div className="product-meta">
                        <div className="product-name innerB half">
                            {data.name}
                        </div>
                        <div className="product-description innerB half">
                            {data.description}
                        </div>
                    </div>

                    <div className="product-action">

                        <div className="product-price">
                            {(!variants || !variants.length) && `$ ${data.price}`}
                        </div>
                        <div className="add-to-cart disable-dbl-tap-zoom">
                            {cart.systemOnline === false ? null :
                            <button className="btn" onClick={this.triggerQuantityIncrease}>
                                <i className="fal fa-plus-circle "/>
                            </button>
                            }
                        </div>
                    </div>

                </div>

                <Collapse in={!!products.length}>
                    <div>
                        <ProductRowManage products={products} qtyDown={this.qtyDown} />
                    </div>
                </Collapse>

                <Modal
                    show={addModal}
                    centered
                    size="sm"
                    backdrop={true}
                    dialogClassName="product-variant-add"
                    scrollable={false}
                    onHide={() => this.setState({addModal: false})}
                >
                    <Modal.Body>
                        <div className="variant-buttons">
                            <h4>{data.name}</h4>
                            <h6>Escoge una opción</h6>
                            <div className="extras-list">
                                {!variants || !variants.length ? null :
                                    variants.map((variant, index) => {
                                        if (!variant.id || !variant.type) {
                                            return null;
                                        }
                                        const variantAdd = (ev) => this.toggleExtraIngredient(ev, variant);

                                        return (
                                            <Form.Check
                                                custom
                                                key={`prv-${variant.name}-${variant.id}-${index}`}
                                                name="selectedVariant"
                                                type={variant.type === 'select' ? 'radio' : 'checkbox'}
                                                value={variant.name}
                                                onChange={variantAdd}
                                                id={`variant-${variant.id}`}
                                                label={(<>{variant.name} {!variant.price ? null : <span>{`$ ${variant.price}`}</span>}</>)}
                                            />
                                        )
                                    })
                                }
                            </div>
                            <div className="text-center">
                                <Button size="lg" variant="primary" onClick={this.qtyUpWithExtras}>Agregar a la orden</Button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>


            </div>
        )
    }
}

export default ProductRow;
