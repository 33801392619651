import React from 'react';
import _ from 'lodash';
import {Modal, Table} from 'react-bootstrap';

const translate = (str) => str;
const localize = (type, str) => '$ ' + str;

class DetailsDialog extends React.Component {
  handleClose = () => {
    const { handleClose } = this.props;
    handleClose();
  };

  render() {
    let { data } = this.props;
    if (!data) {
      data = {};
    }

    if (!data.cart_details) {
      data = {
        cart_details: {},
      };
    }

    const classes = {
      totalLabel: {
        display: 'inline-block',
        marginRight: '10px',
      },
      totalValue: {
        display: 'inline-block',
        width: '60px',
      }
    };

    const {
      products_cost: totalProducts,
      total_cost: totalPrice,
      discount_value: totalDiscount,
      delivery_cost: totalDelivery,
      payment_details: paymentDetails,
    } = data.cart_details;

    let productsTable = null;
    if (data.products && data.products.length) {
      productsTable = data.products.map((product, index) => {
        return (
          <tr key={`prod-${index}`}>
            <td align="right" style={{ width: 50, background: '#f2f2f2' }}>
              {' '}
              <div className={classes.qtyField}>{product.qty} x</div>
            </td>
            <td>
              <div style={{ fontWeight: 500 }}>{product.name}</div>
              {!product.extras || !product.extras.length ? null : (
                <span className={classes.extras}>
                  Extra: {_.map(product.extras, (o) => o.name) ? _.map(product.extras, (o) => o.name).join(', ') : ''}
                </span>
              )}
              <div><i>{product.note}</i></div>
            </td>
            <td align="right" style={{width: 70}}>$ {product.qty * product.price}</td>
          </tr>
        );
      });
    }

    let paymentOverview = '';
    if (data.payment_type === 'PayPal') {
      paymentOverview =
        paymentDetails && paymentDetails.orderID ? (
          <div>{` (Order ID: ${paymentDetails.orderID})`}</div>
        ) : (
          <div className={classes.textDanger}> {translate('ERROR: Missing PayPal transaction data')}</div>
        );
    } else if (data.payment_type === 'Stripe') {
      if (!paymentDetails || !paymentDetails.PaymentIntent || !paymentDetails.PaymentIntent.id) {
        paymentOverview = (
          <div className={classes.textDanger}> {translate('ERROR: Missing Stripe transaction data')}</div>
        );
      } else {
        let paymentError = false;
        const amountPaid = paymentDetails.PaymentIntent.amount / 100;
        if (parseFloat(amountPaid) < parseFloat(totalPrice)) {
          paymentError = true;
        }
        paymentOverview = (
          <div className={[paymentError && classes.textDanger].join(' ')}>
            {translate('Payment ID: {{id}}', { id: paymentDetails.PaymentIntent.id })} <br />
            {!paymentError ? null : 'ERROR: '}
            {translate('Amount Paid: {{amount}} {{currency}}', {
              amount: amountPaid,
              currency: paymentDetails.PaymentIntent.currency,
            })}
          </div>
        );
      }
    }

    return (
      <Modal onHide={this.handleClose} show={this.props.open}>

        <Modal.Header closeButton>
          <Modal.Title>{translate('Order details')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            <Table className="table-details" size="small">
              <tbody>
                <tr>
                  <td variant="body" className={classes.colHead}>
                    {translate('First name')}
                  </td>
                  <td>{data.first_name}</td>
                </tr>
                <tr>
                  <td variant="body" className={classes.colHead}>
                    {translate('Last name')}
                  </td>
                  <td>{data.last_name}</td>
                </tr>
                <tr>
                  <td variant="body" className={classes.colHead}>
                    {translate('Phone')}
                  </td>
                  <td>{data.phone}</td>
                </tr>
                <tr>
                  <td variant="body" className={classes.colHead}>
                    {translate('Email')}
                  </td>
                  <td>{data.email}</td>
                </tr>

                <tr>
                  <td variant="body" className={classes.colHead}>
                    {translate('Order type')}
                  </td>
                  <td>{data.order_type}</td>
                </tr>
                <tr>
                  <td variant="body" className={classes.colHead}>
                    {translate('Payment')}
                  </td>
                  <td>
                    {data.payment_type}
                  </td>
                </tr>
                <tr>
                  <td variant="body" className={classes.colHead}>
                    {translate('Include cutlery')}
                  </td>
                  <td>
                    {data.required_cutlery && (data.required_cutlery === true || data.required_cutlery === 'true')
                      ? translate('Yes')
                      : translate('No')}
                  </td>
                </tr>

                {data.order_type === 'Pick Up' ? null : (
                  <>
                  <tr>
                    <td variant="body" className={classes.colHead}>
                      {translate('Postcode')}
                    </td>
                    <td>{data.postcode}</td>
                  </tr>
                  <tr>
                    <td variant="body" className={classes.colHead}>
                      {translate('Address')}
                    </td>
                    <td>{data.address}</td>
                  </tr>
                  <tr>
                    <td variant="body" className={classes.colHead}>
                      {translate('Address details')}
                    </td>
                    <td>{data.address_details}</td>
                  </tr>
                  </>
                  )
                }

                <tr>
                  <td variant="body" className={classes.colHead}>
                    {translate('Special instructions')}
                  </td>
                  <td>{data.instructions}</td>
                </tr>
              </tbody>
            </Table>
          </div>

          <div className={classes.productsCard}>
            <h3>{translate('Products')}</h3>
            <Table className="table-products" size="small">
              <tbody>{productsTable}</tbody>

              <tfoot>
                <tr>
                  <td colSpan={3} align="right">
                    <div className={classes.totalRow}>
                      <span style={classes.totalLabel}>{translate('Products')}</span>
                      <span style={classes.totalValue}>{localize('currency', totalProducts)}</span>
                    </div>
                    <div className={classes.totalRow}>
                      <span style={classes.totalLabel}>{translate('Discount')}</span>
                      <span style={classes.totalValue}>{localize('currency', totalDiscount)}</span>
                    </div>
                    <div className={classes.totalRow}>
                      <span style={classes.totalLabel}>{translate('Delivery')}</span>
                      <span style={classes.totalValue}>{localize('currency', totalDelivery)}</span>
                    </div>
                    <div className={classes.totalRow}>
                      <span style={classes.totalLabel}>
                        <span style={{ fontWeight: 600 }}>{translate('TOTAL')}</span>
                      </span>
                      <span style={classes.totalValue}>
                        <span style={{ fontWeight: 600 }}>{localize('currency', totalPrice)}</span>
                      </span>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </Table>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

DetailsDialog.defaultProps = {
  data: {
    cart_details: {},
  },
};

export default DetailsDialog;
