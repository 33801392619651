import React, {useState} from 'react';
import moment from 'moment-timezone';
import DetailsDialog from './details-dialog';

class OrderRow extends React.Component {
  constructor() {
    super();

    this.state = {
      modalOpen: false,
    }
  }

  setModal = v => this.setState({ modalOpen: v })
  hideModal = () => this.setState({ modalOpen: false })

  render() {
    const { modalOpen } = this.state;
    const { data } = this.props;

    // UI
    let hour = new Date(data.created_at).toLocaleTimeString();
    hour = hour.substr(0, hour.lastIndexOf(':'));

    let date = new Date(data.created_at).toLocaleDateString('es-MX');
    date = date.split('/').join('.');

    const {
      total_cost: totalPrice,
    } = data.cart_details ? data.cart_details : {};

    let statusText = '';
    let statusIcon = null;
    if (data.order_status === 'processing') {
      // Calculate how many minutes until the order is ready
      const finishDate = moment(data.confirmed_at).add(data.is_confirmed, 'minutes');
      let mins = '';
      if (moment().diff(finishDate, 'minutes') > -5) {
        mins = 'in 5 minutes';
      } else {
        mins = moment().to(finishDate);
      }

      //statusText = <div>Your order is currently being made! <br/> We estimate it will be ready in about {mins} minutes</div>;
      statusText = <div>
        Your order is currently being made!
        <div className="text-weight-300 text-dark size14">We estimate it will be ready {mins}</div>
      </div>;
      statusIcon = <i className="fal fa-hat-chef" />;
    } else if (data.order_status === 'new') {
      statusText = <div>We have received your order</div>;
      /*statusIcon = <i className="fal fa-check" />;*/
    } else if (data.order_status === 'ready') {
      if (data.order_type === 'Pick Up') {
        statusText = <div>
          Your order is ready!
          <div className="text-weight-300 text-dark size14">Please come pick it up.</div>
        </div>
      } else {
        statusText = 'Your order is ready and is on it\'s way!';
      }
      statusIcon = <i className="fal fa-check-circle" />;
    } else if (data.order_status === 'completed') {
      statusText = 'Completed';
    } else if (data.order_status === 'canceled') {
      statusText = 'Order canceled';
    }

    return (
      <div className="order-row-container">
        <div className={['order-status', `${data.order_status}Order`].join(' ')}>
          {statusIcon && <div className="status-icon">
            {statusIcon}
          </div>}
          <div className="status-text size15 text-weight-500">
            {statusText}
          </div>
        </div>

        <div className="d-flex order-row">
          <div>#{data.number} <br/>{data.order_type}</div>
          <div>
            <div className="order-amount">$ {totalPrice}</div>
            <div>{`${data.payment_type}`}</div>
          </div>
          {/*<div>{data.order_type}</div>*/}
          <div className="text-center">{hour}<br/>{date}</div>
          <div className="order-action" onClick={() => this.setModal(true)}>
            <div><i className="fal fa-info-circle"/></div>
          </div>
        </div>

        {!modalOpen ? null : <DetailsDialog data={data} open={modalOpen} handleClose={this.hideModal} />}
      </div>
    )
  }
}

export default OrderRow;
