import React from 'react';
import {Button, Form, Modal} from 'react-bootstrap';

class RequireLogin extends React.Component {
  constructor(props) {
    super(props);

    const emailAddress = localStorage.getItem('email_address');

    this.state = {
      emailAddress: emailAddress,
      loggedIn: !!emailAddress,
      showModal: !emailAddress,
      formError: '',
      isLoading: false,
    }
  }

  handleSubmit = async () => {
    const { emailAddress } = this.state;
    this.setState({ isLoading: true, formError: '' });

    if (!emailAddress) {
      this.setState({ formError: 'La dirección de correo electrónico es obligatoria' })
    }

    const formData = {
      email: emailAddress,
    };

    try {
      const response = await fetch(window.API_BASE_URL + '/api/venue/1/customer/account/login', {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(formData).toString()
      })

      this.setState({ isLoading: false });
      const parsed = await response.json();
      if (parsed && parsed.error) {
        this.setState({ formError: parsed.error });
        return;
      }

      localStorage.setItem('email_address', emailAddress);

      this.hideModal();
      if (this.props.onLogin) {
        this.props.onLogin(emailAddress);
      }

    } catch (err) {
      this.setState({ formError: 'Se produjo un error inesperado. Actualice la página e intente nuevamente.' });
    }
  }

  handleChangeEmail = (event) => {
    this.setState({ emailAddress: event.target.value })
  }

  hideModal = () => {
    this.setState({ showModal: false });

    // Redirect to home
    window.location = '/';
  }
  showModal = () => this.setState({ showModal: true })

  render() {
    const { isLoading, loggedIn, formError, showModal } = this.state;

    if (loggedIn) {
      return (
        <div className="login-placeholder">
          ¡Inicie sesión con su dirección de correo electrónico para acceder a los datos de su cuenta!
        </div>
      );
    }

    return (
      <Modal show={showModal} onHide={this.hideModal} centered>
        {/*<Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>*/}
        <Modal.Body>
          <p style={{ textAlign: 'center', marginBottom: 20 }}>
            Inicie sesión con su correo electrónico para acceder a los datos de su cuenta!
          </p>
          <Form.Group controlId="formEmail">
            <Form.Control type="email" placeholder="Enter email" onChange={this.handleChangeEmail} />
            <Form.Text className="login-error">{!formError ? null : formError}</Form.Text>
          </Form.Group>

          <div className="login-button">
            <Button variant="primary" onClick={this.handleSubmit} disabled={isLoading}>
              {isLoading ? 'Logging in…' : 'Login'}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}

export default RequireLogin;
