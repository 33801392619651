import React from "react";
import ReactDOM from "react-dom";
import scriptLoader from "react-async-script-loader";
import Spinner from "react-bootstrap/Spinner";
import CartContext from "./CartContext";

const CLIENT = {
    sandbox: "AYHpZ4kc-aaZyKyNBA3g3xQWVBJLVKdujf39RuQXqNA-mXNhDpupaN3as-sxgkv73K7mgFnzltzklYDO",
    production: "AQ3U-w3AAr0LOA3H6tgyOw4CPH_S4kwyozyx1Bh_d7VwrDu3zZw0MDuP4oA7qy6kD6OMBoaye1jg88L1"
};

const CLIENT_ID =
    process.env.NODE_ENV === "production" ? CLIENT.production : CLIENT.sandbox;

let PaypalButton = null;
class PaypalForm extends React.Component {
    static contextType = CartContext;

    constructor(props) {
        super(props);

        this.state = {
            showButtons: false,
            loading: true,
            paid: false,
            error: '',
        };

        window.React = React;
        window.ReactDOM = ReactDOM;
    }

    componentDidMount() {
        const { isScriptLoaded, isScriptLoadSucceed } = this.props;

        if (isScriptLoaded && isScriptLoadSucceed) {
            PaypalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });
            this.setState({ loading: false, showButtons: true });
        }
    }

    componentWillReceiveProps(nextProps) {
        const { isScriptLoaded, isScriptLoadSucceed } = nextProps;

        const scriptJustLoaded =
            !this.state.showButtons && !this.props.isScriptLoaded && isScriptLoaded;

        if (scriptJustLoaded) {
            if (isScriptLoadSucceed) {
                PaypalButton = window.paypal.Buttons.driver("react", {
                    React,
                    ReactDOM
                });
                this.setState({ loading: false, showButtons: true, error: '' });
            }
        }
    }
    createOrder = (data, actions) => {
        const { formData } = this.props;
        const { cart } = this.context;

        const payer = {
            name: {
                given_name: formData.first_name || '',
                surname: formData.last_name || ''
            },
            address: {
                address_line_1: formData.address || '',
                address_line_2: formData.address_details || '',
                admin_area_2: 'Aguascalientes',
                admin_area_1: 'Aguascalientes',
                postal_code: formData.postcode || '',
                country_code: 'MX'
            },
        };

        if (formData.email) {
            payer.email_address = formData.email;
        }

        // Disabled to avoid Paypal errors
        /*if (formData.phone) {
            payer.phone = {
                phone_type: "MOBILE",
                phone_number: {
                    national_number: formData.phone || ''
                }
            }
        }*/

        return actions.order.create({
            application_context: {
                shipping_preference: 'NO_SHIPPING',
            },
            payer,
            purchase_units: [
                {
                    description: "Online order",
                    amount: {
                        currency_code: "MXN",
                        value: cart.getTotal(),
                    }
                }
            ]
        });
    };

    onApprove = (data, actions) => {
        actions.order.capture().then(details => {
            const paymentData = {
                payerID: data.payerID,
                orderID: data.orderID,
                facilitatorAccessToken: data.facilitatorAccessToken,
            };
            this.props.onPaymentSuccess(paymentData);
            this.setState({ showButtons: false, paid: true, error: '' });
        });
    };

    onError = (error) => {
        this.setState({ error } )
    }

    render() {
        const { error, showButtons, loading, paid } = this.state;
        return (
            <div className="main">
                {loading && <div className="text-center"><Spinner animation="border" variant="primary" /></div>}
                {error && <div className="text-danger mb-2">PayPal error: {error}</div> }

                {showButtons && (
                    <div className="paypal-buttons">
                        <PaypalButton
                            createOrder={(data, actions) => this.createOrder(data, actions)}
                            onApprove={(data, actions) => this.onApprove(data, actions)}
                            onError={(err) => this.onError(err)}
                            onCancel={(data) => this.onError('El pago de PayPal ha sido cancelado. Elija otro método de pago o vuelva a intentarlo.')}
                        />
                    </div>
                )}

                {paid && (
                    <div className="main text-center">
                        <p>Successful payment! Please wait...</p>
                        <div><Spinner animation="border" variant="primary" /></div>
                    </div>
                )}
            </div>
        );
    }
}

export default scriptLoader(`https://www.paypal.com/sdk/js?client-id=${CLIENT_ID}&currency=MXN`)(PaypalForm);
