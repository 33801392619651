import React from 'react';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';


// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const PUBLIC_KEYS = {
  production: 'pk_live_BhoerP3QEZz4oG4LywUHnseR00sA94KO3w',
  development: 'pk_test_UCZ9denEWTuLRiZKc15yqXaX000nrg2CTL',
}
const PUBLIC_KEY =
  process.env.NODE_ENV === "production" ? PUBLIC_KEYS.production : PUBLIC_KEYS.development;

const stripePromise = loadStripe(PUBLIC_KEY);

const StripeInjectedComponent = ({component, ...rest}) => {
  const Component = component;
  return (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>
        {({elements, stripe}) => (
          <Component {...rest} elements={elements} stripe={stripe} />
        )}
      </ElementsConsumer>
    </Elements>
  );
};

export default StripeInjectedComponent;
