import * as React from "react";
import _ from "lodash";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import CartContext from "../../components/CartContext";
import ApiClient from "../../components/ApiClient";
import OrderConfirmation from "./OrderConfirmationPopup";
import ShoppingCart from "./ShoppingCart";
import {Link} from "react-router-dom";
import AppState from "../../stores/AppState";
import PaypalForm from "../../components/PaypalForm";
import StripeForm from '../../components/StripeForm';
import {CardElement, CardNumberElement} from '@stripe/react-stripe-js';
import SystemOfflineAlert from '../../components/SystemOfflineAlert';

let checkConfirmationInterval;


class OrderCheckout extends React.Component {

    static contextType = CartContext;

    cart = null;

    constructor(props, context) {
        super(props, context);

        const { cart } = this.context;
        const formData = cart.getCustomerDetails() || {};
        cart.setOrderField('first_name', formData.first_name ? formData.first_name : '');
        cart.setOrderField('last_name', formData.last_name ? formData.last_name : '');
        cart.setOrderField('phone', formData.phone ? formData.phone : '');
        cart.setOrderField('email', formData.email ? formData.email : '');
        cart.setOrderField('postcode', formData.postcode ? formData.postcode : '');
        cart.setOrderField('address', formData.address ? formData.address : '');
        cart.setOrderField('address_details', formData.address_details ? formData.address_details : '');

        this.state = {
            loading: false,
            orderId: null,
            order_type: 'Pick Up',
            payment_type: 'Stripe',
            paid: false,
            paymentError: false,
            paymentErrorMessage: '',

            first_name: formData.first_name || '',
            last_name: formData.last_name || '',
            phone: formData.phone || '',
            email: formData.email || '',
            postcode: formData.postcode || '',
            address: formData.address || '',
            address_details: formData.address_details || '',

            stripeReady: false,
            cardNumber: false,
            cardExpiry: false,
            cardCvc: false,
        }
    }

    handleChange = (event) => {
        const { cart } = this.context;

        const field = event.target.name;
        const value = event.target.value;

        cart.setOrderField(field, value ? value : '');

        this.setState({ [field]: value ? value : '' }, () => {

        });
    }

    handleQuantityChange = (name, qty) => {
        const { cart } = this.context;
        let { products } = this.state;

        const product = _.find(products, {name})
        qty = qty ? qty : 0;

        if (product && product.name) {
            cart.updateQuantity(product, qty);

            this.setState({cart: cart.getItems()})
        }
    }

    handlePaypalPaymentSuccess = (details) => {
        const { cart } = this.context;
        cart.setOrderField('payment_details', details);

        this.handleSubmit();
    }

    handleStripeElementChange = (event) => {
        this.setState({[event.elementType]: event.complete}, () => {
            const { cardNumber, cardExpiry, cardCvc } = this.state;
            const stripeReady = !!(cardNumber && cardExpiry && cardCvc);
            this.setState({ stripeReady });
        });
    }

    handleSubmit = async (ev) => {
        ev && ev.preventDefault()
        ev && ev.stopPropagation();

        const { elements, stripe } = this.props;

        // Enable loading
        this.setState({
            loading: true,
            waiting: false,
            paymentError: false,
            paymentErrorMessage: '',
        })

        // Prepare data
        const data = this.context.cart.getOrder();
        data.products = this.context.cart.getItems();

        // Get Stripe PaymentIntent from server, if needed
        let paymentSuccess = null, paymentDetails = null;

        if (data.payment_type === 'Stripe') {
            try {
                // Create intent
                const clientSecret = await ApiClient.createPaymentIntent({ ...data });

                // Charge payment
                const payload = await stripe.confirmCardPayment(clientSecret, {
                    payment_method: {
                        card: elements.getElement(CardNumberElement),
                        billing_details: {
                            name: data.first_name + ' ' + data.last_name,
                            email: data.email,
                        }
                    }
                });


                if (payload.error) {
                    //console.log("[error]", payload.error);
                    this.setState({
                        loading: false,
                        paymentError: true,
                        paymentErrorMessage: payload.error.message,
                    });

                    return;
                } else {
                    const { cart } = this.context;
                    cart.setOrderField('payment_details', {
                        'PaymentIntent': payload.paymentIntent,
                    });
                }
            } catch (err) {
                //console.log(err)
                this.setState({
                    loading: false,
                    paymentError: true,
                })

                return;
            }
        }

        // Send as AJAX
        ApiClient.post({
            endpoint: window.API_BASE_URL + '/api/venue/1/order',
            data,
        })
          .then((res) => {

            if (!res || !res.body || !res.body.data || !res.body.data.id) {
                alert('Se produjo un error al guardar el pedido. Por favor intente ordenar nuevamente o contáctenos.');

                this.setState({loading: false});
                return;
            }

            // Save data to local storage
              const { cart } = this.context;
            cart.setCustomerDetails({
                first_name: data.first_name,
                last_name: data.last_name,
                phone: data.phone,
                postcode: data.postcode,
                address: data.address,
                address_details: data.address_details,
            })

            // OK, let's ask the user to wait for order confirmation
            const orderId = res.body.data.id_string;
            setTimeout(() => {
                this.setState({
                    loading: false,
                    waiting: true,
                    orderId
                })

                // Let's start an interval which checks if the order was confirmed
                const checkConfirmation = async () => {
                    const response = await ApiClient.get({
                        endpoint: window.API_BASE_URL + '/api/venue/1/order/' + orderId,
                    })

                    if (!response || !response.body || !response.body.data || !response.body.data.id_string) {

                        return;
                    }

                    const order = response.body.data;

                    if (order && order.is_confirmed) {
                        this.setState({
                            loading: false,
                            waiting: true,
                            confirmed: order.is_confirmed,
                        })

                        clearInterval(checkConfirmationInterval);
                    }
                }

                checkConfirmationInterval = setInterval(checkConfirmation, 5000)

            }, 200)

        }, (err) => {
            this.setState({
                loading: false,
                waiting: false,
            })
            if (err && err.response && err.response.body && err.response.body.error) {
                if (err.response.body.error === 'System offline') {
                    this.setState({ renderSystemOffline: true });
                    return;
                }
            }
            ApiClient.onError(err)
        });
    }

    componentDidMount() {
        const {cart} = this.context;
        const categories = AppState.get('online_menu');

        let products = [];

        if (categories) {
            for (const category of categories) {
                products = _.concat(products, category.products)
            }
        }

        this.setState({products})

        const {order_type, payment_type} = this.state;
        cart.setOrderField('order_type', order_type);
        cart.setOrderField('payment_type', payment_type);
    }

    componentWillUnmount() {
        clearInterval(checkConfirmationInterval);
    }

    render() {

        const {state} = this;
        const {cart} = this.context;

        const totalPrice    = cart.getTotal();
        const totalProducts = cart.getTotalItems();
        const totalDiscount = cart.getDiscount();
        const deliveryPrice = cart.getDelivery();
        const {order_type, renderSystemOffline} = state;

        const shouldUseDiscount = order_type === 'Pick Up';
        const deliveryEnabled = cart.systemConfig.ordering_online_delivery_service === 'on';
        const paypalEnabled = cart.systemConfig.ordering_online_paypal_payments === 'on';
        const stripeEnabled = cart.systemConfig.ordering_online_stripe_payments === 'on';

        return (

            <div className="checkout">
                {renderSystemOffline && <SystemOfflineAlert />}
                <OrderConfirmation orderId={state.orderId} loading={state.loading} waiting={state.waiting} confirmed={state.confirmed} />

                <div className="navbar mb-2">
                    <Link to="/new" className=""><i className="fal fa-chevron-left mr-2"/>Volver al menú</Link>
                </div>

                <Row>
                    <Col xs={12}>
                        <ShoppingCart
                            shouldUseDiscount={shouldUseDiscount}
                            sidebar={false}
                            cart={cart}
                            onQtyChange={this.handleQuantityChange}
                        />
                    </Col>
                </Row>
                <Row>

                <Col>
                    <Form onSubmit={this.handleSubmit}>
                        <div className="checkout-card">
                            <Card>
                                <Card.Body>
                                    <Card.Title>Detalles del pedido</Card.Title>

                                    <div className="card-content">

                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Necessitas Servietas, Tenedor, etc?</Form.Label>
                                                    <Form.Check
                                                        onChange={(ev) => this.handleChange({ target: { name: 'required_cutlery', value: !!ev.target.checked}})}
                                                        name="required_cutlery"
                                                        id="required_cutlery"
                                                        custom
                                                        type="switch"
                                                        label={`Si, lo necesito`}
                                                    />
                                                </Form.Group>

                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Instrucciones especiales</Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        value={state.instructions || ''}
                                                        onChange={this.handleChange}
                                                        name="instructions"
                                                        id="instructions"
                                                        placeholder={'Háganos saber si tiene instrucciones especiales'}
                                                    />
                                                </Form.Group>

                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Tipo de entrega</Form.Label>
                                                    <Form.Control
                                                        required
                                                        value={state.order_type || ''}
                                                        onChange={this.handleChange}
                                                        name="order_type"
                                                        as="select" >
                                                        <option value="Pick Up">Recoger en Campfire (recibes {cart.getSystemConfig('ordering_online_pickup_discount')}% descuento)</option>
                                                        {/*<option value="Pick Up">Recoger en Campfire (descuentos aplican)</option>*/}

                                                        <option value="Delivery" disabled={!deliveryEnabled}>
                                                            Entrega a Domicilio (+ ${cart.getSystemConfig('ordering_online_delivery_fee')})
                                                            {deliveryEnabled ? null : ' (no disponible)'}
                                                        </option>
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Nombre *</Form.Label>
                                                    <Form.Control
                                                        value={state.first_name || ''}
                                                        onChange={this.handleChange}
                                                        required type="text" name="first_name" placeholder="" />
                                                </Form.Group>
                                            </Col>

                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Apellido *</Form.Label>
                                                    <Form.Control
                                                        value={state.last_name || ''}
                                                        onChange={this.handleChange}
                                                        required type="text" name="last_name" placeholder="" />
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Telefono *</Form.Label>
                                                    <Form.Control
                                                        value={state.phone || ''}
                                                        onChange={this.handleChange}
                                                        type="text" name="phone" placeholder="" required />
                                                </Form.Group>
                                            </Col>

                                            {state.order_type !== 'Delivery' ? null :
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>Codigo Postal</Form.Label>
                                                        <Form.Control
                                                            value={state.postcode || ''}
                                                            onChange={this.handleChange}
                                                            type="text" name="postcode" placeholder=""  />
                                                    </Form.Group>
                                                </Col>
                                            }
                                        </Row>

                                        {state.order_type !== 'Delivery' ? null : <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Direccion *</Form.Label>
                                                    <Form.Control
                                                        value={state.address || ''}
                                                        onChange={this.handleChange}
                                                        type="text" name="address" placeholder="" required />
                                                </Form.Group>
                                            </Col>
                                        </Row>}

                                        {state.order_type !== 'Delivery' ? null : <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Entre cual Calles *</Form.Label>
                                                    <Form.Control
                                                        value={state.address_details || ''}
                                                        onChange={this.handleChange}
                                                        type="text" name="address_details" placeholder="" required  />
                                                </Form.Group>
                                            </Col>
                                        </Row>}
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>


                        <div className="checkout-card">
                            <Card>
                                <Card.Body>
                                    <Card.Title>Detalles del pago</Card.Title>

                                    <div className="card-content">


                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Email address *</Form.Label>
                                                    <Form.Control
                                                        required
                                                        value={state.email || ''}
                                                        onChange={this.handleChange}
                                                        name="email"
                                                        type="email" placeholder="" />
                                                    <Form.Text className="text-muted">
                                                        Enviaremos notificaciones de pedidos a esta dirección de correo electrónico.
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Typo de pago</Form.Label>
                                                    <Form.Control
                                                        value={state.payment_type || ''}
                                                        onChange={this.handleChange}
                                                        name="payment_type"
                                                        as="select" >
                                                        <option value="Efectivo">Efectivo</option>
                                                        <option value="Tarjeta de Credito">Tarjeta de crédito en Campfire</option>
                                                        {!paypalEnabled ? null : (
                                                          <option value="PayPal">
                                                            PayPal
                                                          </option>
                                                          )
                                                        }
                                                        <option value="Stripe" disabled={!stripeEnabled}>
                                                            Tarjeta de crédito en línea {stripeEnabled ? null : '(no disponible)'}
                                                        </option>
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        {state.payment_type !== 'PayPal' ? null :
                                        <PaypalForm formData={state} onPaymentSuccess={this.handlePaypalPaymentSuccess} />
                                        }

                                        {state.payment_type !== 'Stripe' ? null :
                                          <StripeForm onChange={this.handleStripeElementChange} />
                                        }

                                        {state.paymentError &&
                                            <div className="text-danger my-2">
                                                {state.paymentErrorMessage
                                                  ? state.paymentErrorMessage
                                                  : 'Se produjo un error al procesar el pago con tarjeta de crédito. Inténtalo de nuevo.'
                                                }
                                            </div>
                                        }

                                        <div className="product-summary border-bottom-none d-block d-md-none">
                                            <div className="d-flex size18 mb-2">
                                                <div className="text-weight-300 flex-grow-1">Productos</div>
                                                <div className="text-weight-300">$ {totalProducts}</div>
                                            </div>
                                            {!totalDiscount ? null :
                                            <>
                                                <div className="d-flex size18 mb-2">
                                                    <div className="text-weight-300 flex-grow-1">Discount</div>
                                                    <div className="text-weight-300">$ -{totalDiscount}</div>
                                                </div>
                                            </>
                                            }
                                            <div className="d-flex size18 mb-2">
                                                <div className="text-weight-300 flex-grow-1">Entrega</div>
                                                <div className="text-weight-300">$ {deliveryPrice}</div>
                                            </div>
                                            <div className="d-flex size20 mt-4">
                                                <div className="text-weight-500 flex-grow-1">TOTAL</div>
                                                <div className="text-weight-500">$ {totalPrice}</div>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>

                        <div className="checkout-button">
                            <button
                                type="submit"
                                disabled={(state.payment_type === 'Stripe' && !state.stripeReady) || renderSystemOffline}
                                className="btn btn-primary btn-lg">
                                Finalizar
                            </button>
                        </div>
                    </Form>
                </Col>

                <Col md={5}>
                    <ShoppingCart
                        shouldUseDiscount={shouldUseDiscount}
                        sidebar={true}
                        cart={cart}
                        onQtyChange={this.handleQuantityChange}
                    />
                </Col>
                </Row>
            </div>
        )
    }
}

export default OrderCheckout;
