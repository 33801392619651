import React from 'react';
import RequireLogin from './login-modal';
import Orders from './orders-list';

class AccountOrders extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: localStorage.getItem('email_address'),
    }
  }

  handleLogin = email => this.setState({ email })

  render() {
    const { email } = this.state;

    return (
      <div className="orders-list">
        {email ? null : <RequireLogin onLogin={this.handleLogin} />}

        <Orders email={email}></Orders>
      </div>
    );
  }
}

export default AccountOrders;
