import React from 'react';
import StripeForm from '../../components/StripeForm';
import {Button, Card, Col, Fade, Form, FormControl, InputGroup, Row, Spinner} from 'react-bootstrap';
import ApiClient from '../../components/ApiClient';
import {CardNumberElement} from '@stripe/react-stripe-js';
import AppState from '../../stores/AppState';

const copyToClipboard = str => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

class BillPayment extends React.Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    this.state = {
      renderStripe: false,
      amount: 0,
      tip_amount: 0,
      server_name: '',
      note: '',
      loading: false,
      paymentSuccess: false,
      showThankYou: false,
      paymentType: 'Card en linea',

      stripeReady: false,
      cardNumber: false,
      cardExpiry: false,
      cardCvc: false,
      errors: {},
    }
  }

  componentDidMount() {
    setTimeout(() => this.setState({ renderStripe: true } ), 300);
  }

  handleSubmit = async ev => {
    ev.preventDefault();
    ev.stopPropagation();

    // Ajax submit
    const { elements, stripe } = this.props;

    // Enable loading
    this.setState({
      loading: true,
      waiting: false,
      paymentSuccess: false,
      showThankYou: false,
      paymentError: false,
      paymentErrorMessage: '',
    })

    // Prepare data
    const { amount, tip_amount } = this.state;
    let total = 0;
    total += parseFloat(amount);
    total += parseFloat(tip_amount);
    total = total.toString();

    try {
      // Create intent
      const clientSecret = await ApiClient.createPaymentIntent({
        amount: total,
        type: 'bill',
      });

      // Charge payment
      const payload = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          /*billing_details: {
            name: data.first_name + ' ' + data.last_name,
            email: data.email,
          }*/
        }
      });

      // Error while building the payment?
      if (payload.error) {
        this.setState({
          loading: false,
          paymentError: true,
          paymentErrorMessage: payload.error.message,
        });

        window.scrollTo(0, 0);

        return false;
      }

      // Payment successful, save it to server
      const res = await ApiClient.post({
        endpoint: window.API_BASE_URL + '/api/venue/1/payment/register-bill-payment',
        data: {
          amount: total,
          tip_amount: this.state.tip_amount,
          server_name: this.state.server_name,
          note: this.state.note,
          transaction_id: payload.paymentIntent.id,
          transaction_meta: payload.paymentIntent,
        }
      });

      if (!res || !res.body || !res.body.data || !res.body.data.id) {
        this.setState({
          loading: false,
          paymentError: true,
          paymentErrorMessage: 'Se produjo un error al guardar el pago. El pago se procesó y se cargó a su tarjeta de crédito. Por favor icontáctenos.',
        });

        window.scrollTo(0, 0);
        return;
      }

      // All good, show whistles and everything!
      this.setState({
        paymentSuccess: true,
        loading: false,
      });

      setTimeout(() => {
        this.setState({ paymentSuccess: false, showThankYou: true });

        // Reset form
        if (this.formRef && this.formRef.current) {
          this.formRef.current.reset();
          window.scrollTo(0, 0);
        }
      }, 2000)

    } catch (err) {
      this.setState({
        loading: false,
        paymentError: true,
        paymentErrorMessage: 'Se produjo un error al guardar el pago. Por favor icontáctenos.',
      })

      window.scrollTo(0, 0);
      return;
    }

    return false;
  }

  handleCopy = (str) => {
    copyToClipboard(str);
    AppState.enqueueSnackbar('CLABE copiado');
    this.setState(
      { copied: true },
      () => setTimeout(() => this.setState( {copied: false}), 3000)
    );
  }

  handleChange = ev => {
    this.setState({ [ev.target.name]: ev.target.value })
  }

  handleChangePayment = method => {
    this.setState({ paymentType: method })
  }

  handleStripeElementChange = event => {
    this.setState({[event.elementType]: event.complete}, () => {
      const { cardNumber, cardExpiry, cardCvc } = this.state;
      const stripeReady = !!(cardNumber && cardExpiry && cardCvc);
      this.setState({ stripeReady });
    });
  }

  render() {
    const {
      amount,
      tip_amount,
      copied,
      loading,
      renderStripe,
      stripeReady,
      paymentErrorMessage,
      paymentSuccess,
      paymentType,
      showThankYou,
    } = this.state;

    let total = 0;
    total += parseFloat(amount);
    total += parseFloat(tip_amount);

    return (
      <div className="payment-bill-form">
        <div className="content-semibox">
          <Form ref={this.formRef} onSubmit={this.handleSubmit} className="w-100">
            <Card>
              <Card.Body>
                <Card.Title>Pagar su cuenta</Card.Title>

                <div className="card-content">
                  {(loading || paymentSuccess) && (
                    <div className="loading-circle-container">
                      <div className={['circle-loader', paymentSuccess && 'load-complete'].join(' ')}>
                        <div className="checkmark draw" style={{ display: paymentSuccess ? 'block' : 'none'}} />
                      </div>
                    </div>
                  )}

                  {paymentErrorMessage && <div className="text-danger my-4">{paymentErrorMessage}</div> }
                  {showThankYou && <div className="text-success my-4">Gracias, su pago ha sido recibido!</div> }
                  <Row>

                    <Col xs={12}>
                      <Form.Group>
                        <Form.Label className="text-weight-500">Método de pago</Form.Label>
                        <div>
                          <Form.Check
                            className="pb-2"
                            name="payment_type"
                            onChange={() => this.handleChangePayment('Card en linea')}
                            label="Card en linea" type="radio" id={`metodo-card`}
                            checked={!paymentType || paymentType === 'Card en linea'} />
                          <Form.Check
                            className="pb-2"
                            name="payment_type"
                            onChange={() => this.handleChangePayment('Transferencia Bancaria')}
                            checked={paymentType === 'Transferencia Bancaria'}
                            label="Transferencia Bancaria" type="radio" id={`metodo-transfer`} />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>

                  <div style={{position: 'relative'}}>
                  <div style={{position: 'relative'}}>
                    {paymentType !== 'Transferencia Bancaria' ? null :
                      <Fade in={true} appear={true}>
                        <div style={{position: 'relative', height: '100%'}}>
                          <div className="">
                            <span className="text-weight-500">Nombre</span>
                          </div>
                          <div className=" mb-3">
                            Joshua Jacob Alner
                          </div>

                          <div className="">
                            <span className="text-weight-500">Banco</span>
                          </div>
                          <div className=" mb-3">
                            Ban Regio
                          </div>

                          <div>
                            <span className="text-weight-500">Cuenta</span>
                          </div>
                          <div className=" mb-3">
                            200914560011
                          </div>

                          <div>
                            <span className="text-weight-500">CLABE</span>
                          </div>
                          <div className=" mb-3">
                            <span className="size18 d-inline-block mr-3 mb-2">058010000003997738</span>
                            <Button
                              variant="light"
                              size="md"
                              disabled={!!copied}
                              onClick={() => this.handleCopy('058010000003997738')}>
                              {!copied ? 'COPIAR' : 'COPIADO!'}
                            </Button>
                          </div>

                        </div>
                      </Fade>
                    }

                    {paymentType !== 'Card en linea' ? null :
                    <Fade in={true} appear={true}>
                      <div style={{position: 'relative', height: '100%'}}>
                          <Row>
                          <Col sm={6}>
                            <Form.Group controlId="valor">
                              <Form.Label>Valor *</Form.Label>
                              <InputGroup style={{maxWidth: 140}}>
                                <InputGroup.Prepend>
                                  <InputGroup.Text>$</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                  type="text"
                                  inputMode="decimal"
                                  min="0"
                                  name="amount"
                                  placeholder="0.00"
                                  required
                                  onChange={this.handleChange}
                                />
                              </InputGroup>
                              <Form.Text className="text-muted">
                                Ingrese la valor tal como está en la cuenta.
                              </Form.Text>
                            </Form.Group>
                          </Col>

                            <Col sm={6}>
                              <Form.Group controlId="tip_amount">
                                <Form.Label>Propina</Form.Label>
                                <InputGroup style={{maxWidth: 140}}>
                                  <InputGroup.Prepend>
                                    <InputGroup.Text>$</InputGroup.Text>
                                  </InputGroup.Prepend>
                                  <Form.Control
                                    type="text"
                                    inputMode="decimal"
                                    min="0"
                                    name="tip_amount"
                                    placeholder="0.00"
                                    onChange={this.handleChange}
                                  />
                                </InputGroup>
                              </Form.Group>
                            </Col>

                          <Col sm={6}>
                          <Form.Group controlId="server_name">
                          <Form.Label>Nombre del servidor *</Form.Label>
                          <Form.Control name="server_name" type="text" placeholder="" required onChange={this.handleChange} />
                          </Form.Group>
                          </Col>

                          <Col sm={6}>
                          <Form.Group controlId="note">
                          <Form.Label>Nota</Form.Label>
                          <Form.Control name="note" row="2" as="textarea" onChange={this.handleChange} />
                          </Form.Group>
                          </Col>

                          <Col sm={12}>
                          <div className="payment-form">
                          {!renderStripe && <Spinner animation="border"/>}
                          {renderStripe && <StripeForm onChange={this.handleStripeElementChange}/>}
                          </div>
                          </Col>
                        </Row>
                      </div>
                      </Fade>
                    }
                  </div>
                  </div>
                </div>
              </Card.Body>
            </Card>

            <div className="checkout-button">
              <Button
                className="btn-big-action btn btn-primary btn-lg"
                variant="primary"
                type="submit"
                disabled={!stripeReady || loading}>
                {`Enviar pago for $${total}`}
              </Button>
            </div>

          </Form>
      </div>
      </div>
    );
  }
}

export default BillPayment;
