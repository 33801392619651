import _ from 'lodash';
var EventEmitter = require('events').EventEmitter;
var emitter = new EventEmitter();

var appState = {
    site_name: '',
    current_order: null,
    cart: [],
};


export default {
    get: function(name) {
        const storage = JSON.parse(localStorage.getItem('app_state'))
        if (storage) {
            appState = storage;
        }

        return name ? appState[name] : appState;
    },

    subscribe: function(callback, event) {
        emitter.on(event ? event : 'update', callback);
    },

    unsubscribe: function(callback, event) {
        emitter.removeListener(event ? event : 'update', callback);
    },

    set: function(key, data) {
        appState[key] = data;

        localStorage.setItem('app_state', JSON.stringify(appState))

        emitter.emit('update');
    },

    enqueueSnackbar: function (message) {
        let queuedMessages = this.get('snackbar_messages');
        if (!queuedMessages || !_.isArray(queuedMessages)) {
            queuedMessages = [];
        }

        if (typeof message === 'string') {
            queuedMessages = [...queuedMessages, { message }]
        } else {
            queuedMessages = [...queuedMessages, message ];
        }

        this.set('snackbar_messages', queuedMessages)
    },

    removeSnackbar: function (index) {
        let queuedMessages = this.get('snackbar_messages');
        queuedMessages.splice(index, 1);

        this.set('snackbar_messages', [...queuedMessages])
    }
};
