import React from 'react';
import {Card, Spinner} from 'react-bootstrap';
import OrderRow from './order-row';

class OrdersList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: this.props.email,
      isLoading: false,
      loaded: false,
      open: [],
      completed: []
    }
  }

  componentDidMount() {
    this.fetchOrders();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.email !== this.props.email) {
      this.fetchOrders();
    }
  }

  fetchOrders = async () => {
    if (!this.props.email) {
      return false;
    }

    this.setState({ isLoading: true });

    try {
      const response = await fetch(window.API_BASE_URL + '/api/venue/1/customer/account/orders', {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({ email: this.props.email }),
      })

      this.setState({ isLoading: false, loaded: true });

      const parsed = await response.json();
      if (parsed && parsed.error) {
        this.setState({ formError: parsed.error });
        return;
      }

      this.setState({
        open: parsed.data.filter(o => ['new', 'ready', 'processing'].indexOf(o.order_status) !== -1),
        completed: parsed.data.filter(o => ['new', 'ready', 'processing'].indexOf(o.order_status) === -1),
      })
    } catch (err) {
      this.setState({ isLoading: false, loaded: true, formError: true });
    }
  }

  render() {
    const { open, completed, isLoading, loaded } = this.state;
    const lclass = ["page-loading", loaded ? "not-show" : ''].join(' ');

    return (
      <div>
        <div className={lclass}>
          <Spinner animation="border" variant="primary" />
        </div>
        <Card>
          <Card.Body>
            <Card.Title>Mis pedidos</Card.Title>
            <div>
              {!open.length ? <p className="py-2 px-4">No hay pedidos</p> :
                open.map(o => <OrderRow key={o.number} data={o} />)
              }
            </div>
          </Card.Body>
        </Card>

        <Card>
          <Card.Body>
            <Card.Title>Pedidos pasadas</Card.Title>
            <div>
              {!completed.length ? <p className="py-2 px-4">No hay pedidos pasados</p> :
                completed.map(o => <OrderRow key={o.number} data={o} />)
              }
            </div>
          </Card.Body>
        </Card>
      </div>
    )
  }
}

export default OrdersList;
