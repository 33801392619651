import * as React from "react";
import PropTypes from 'prop-types';
import _ from "lodash";
import Form from "react-bootstrap/Form";
import CartContext from "../../components/CartContext";
import Collapse from "react-bootstrap/Collapse";


class Product extends React.Component {
    static contextType = CartContext;

    static propTypes = {
        product: PropTypes.object.isRequired,
        productQtyDown: PropTypes.func.isRequired,
    }

    state = {
        note: ''
    }

    componentDidMount() {
        const {note} = this.props;

        this.setState({note})
    }

    onNoteChange = (ev) => {

        const {product} = this.props;
        let note = ev && ev.target && ev.target.value;
        note = note ? note : '';

        this.setState({note}, () => this.props.changedNote && this.props.changedNote(product.name,note))
    }

    render() {
        const {note: defaultNote, product: p, productQtyDown} = this.props;
        const {note} = this.state;

        return (
            <React.Fragment>
                <div className="d-flex size14 align-items-top mb-1">
                    <div className="qty">{p.qty} &times;</div>
                    <div className="name">
                        {p.variant ? p.variant : p.name}
                        {!p.extras || !p.extras.length ? null :
                            <span className="extras-title d-block">
                                                     Extra: {_.map(p.extras, o => o.name) ? _.map(p.extras, o => o.name).join(', ') : ''}
                                                    </span>
                        }
                    </div>
                    <div className="price">$ {p && (p.price * p.qty)}</div>
                    <div className="remove">
                        <a className="text-muted" onClick={productQtyDown}>
                            <i className="fal fa-times "/>
                        </a>
                    </div>
                </div>

                <div className="product-note">
                    <Form.Control onChange={this.onNoteChange}
                                  defaultValue={defaultNote || ''}
                                  size="sm" type="text" placeholder="¿Alguna solicitud extra?" />
                </div>
            </React.Fragment>
        )
    }
}

class ProductRowManage extends React.Component {
    static contextType = CartContext;

    static propTypes = {
        qtyDown: PropTypes.func.isRequired,
    }

    state = {
        open: false,
    }

    qtyDown(name, rowId) {
        this.props.qtyDown(name, rowId)
    }

    changeNote = (name, rowId, value) => {
        const { cart } = this.context;

        if (rowId) {
            cart.updateNote(rowId, name, value)
        }
    }

    render() {
        const { products } = this.props;

        if (!products || !products.length) {
            return null;
        }

        return (

            <div className="product-manage">
                <>
                    {products.map((p, index) => {
                        if (!p) {
                            return null
                        }

                        const productQtyDown = () => this.qtyDown(p.name, p.rowId);
                        const changedNote = (name, value) => {
                            this.changeNote(p.name, p.rowId, value);
                        }

                        return (
                            <Product key={`pr-${p.name}-${index}`}
                                     product={p}
                                     note={p.note || ''}
                                     changedNote={changedNote}
                                     productQtyDown={productQtyDown} />
                        )
                    })}



                </>
            </div>
        )
    }
}

export default ProductRowManage;
