import React from 'react';
import {Button} from 'react-bootstrap';
import { Link } from 'react-router-dom';

class Dashboard extends React.Component {
  render() {
    return (
      <div className="dashboard-content">
        <div className="menu-buttons">
          <div className="menu-button">
            <Button variant="outline-secondary" size="lg" as={Link} to="/new">
              <i className="fal fa-utensils fa-fw" /> Orden en Línea
            </Button>
          </div>

          <div className="menu-button">
            <Button variant="outline-secondary" size="lg" as={Link} to="/account/orders">
              <i className="fal fa-shopping-bag fa-fw" /> Mis Pedidos
            </Button>
          </div>

          <div className="menu-button">
            <Button variant="outline-secondary" size="lg" as={Link} to="/pay-bill">
              <i className="fal fa-credit-card fa-fw" /> Pagar Cuenta en Linea
            </Button>
          </div>

          <div className="menu-button">
            <Button variant="outline-secondary" size="lg" href="https://campfire.mx/menu/" target="_blank">
              <i className="fal fa-burger-soda fa-fw" /> Ver Menú
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
