import React from 'react';
import AppState from '../stores/AppState';
import {Toast} from 'react-bootstrap';
import App from '../App';

class Snackbar extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      messages: [],
    }
  }

  postConfigUpdate = () => {
    let stored = AppState.get('snackbar_messages');
    if (!stored) {
      stored = [];
    }

    this.setState({ messages: [...stored] });
  }

  componentWillMount() {
    AppState.subscribe(this.postConfigUpdate, 'update');
  }

  componentWillUnmount() {
    AppState.unsubscribe(this.postConfigUpdate, 'update');
  }

  render() {
    const { messages } = this.state;

    return (
      <div
        aria-live="polite"
        aria-atomic="true"
        style={{
          position: 'fixed',
          top: '20px',
          right: '30px',
          minHeight: '100px',
          zIndex: 500,
        }}
      >
        {messages.map((data, idx) => {
          const body = data.message ? data.message : data;

          return (

            <Toast
              key={`toast-${body}-${idx}`}
              onClose={() => AppState.removeSnackbar(idx)}
              show={true}
              delay={3000}
              autohide
              >
              {!data.header ? null :
              <Toast.Header>
                <strong className="mr-auto">{data.header}</strong>
              </Toast.Header>
              }
              <Toast.Body>{body}</Toast.Body>
            </Toast>
          )
        })}
      </div>
    )
  }
}

export  default Snackbar;
