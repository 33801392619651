import React, {useState} from 'react';
import {Route, Switch, Redirect, useLocation, Link} from 'react-router-dom';

//import ApiClient from './../components/ApiClient'

import CartContext from './components/CartContext';
import OrderProductsMenu from './routes/menu/OrderProductsMenu';
import OrderCheckout from './routes/checkout/InjectedOrderCheckout';
import CartModel from "./models/Cart";
import ApiClient from "./components/ApiClient";
import Nav from "react-bootstrap/Nav";
import TransitionGroup from "react-transition-group/TransitionGroup";
import CSSTransition from "react-transition-group/CSSTransition";
import Drawer from 'rc-drawer';
import AccountOrders from './routes/account/orders';
import SystemOfflineAlert from './components/SystemOfflineAlert';

import 'rc-drawer/assets/index.css';
import "animate.css/animate.css";
import BillPayment from './routes/payment/bill-payment';
import StripeInjectedComponent from './components/StripeInjectedComponent';
import Snackbar from './components/Snackbar';
import Dashboard from './routes/dashboard/dashboard';

class App extends React.Component {
    state = {
        //cart: process.env.NODE_ENV === 'development' ? new CartModel(AppState.get('cart')) : new CartModel(),
        cart: new CartModel(),
        systemOnline: true,
        systemConfig: {
            // We consider the order system to be on by default
            ordering_online_status: 'on',
        },
        drawerOpen: false,
    }

    showDrawer = ev => {
        ev.preventDefault();
        this.setState({drawerOpen: true})
    }
    hideDrawer = () => this.setState({ drawerOpen: false })

    handleLogout = (ev) => {
        ev.preventDefault();
        localStorage.removeItem('email_address');
        localStorage.removeItem('form_details');

        window.location = '/dashboard';
    }

    constructor(props) {
        super(props);
    }

    getOrderSystemConfig = () => {
        ApiClient.get({
            endpoint: window.API_BASE_URL + '/api/venue/1/order-system/settings'
        }).then((res) => {
            const config = res.body.data;
            this.setState({
                systemConfig: config,
            })
        })
    }

    componentDidMount() {
        this.getOrderSystemConfig()
    }

    render() {
        const { cart, systemConfig } = this.state;
        const systemOnline = systemConfig.ordering_online_status === 'on'

        cart.systemOnline = systemOnline;
        cart.systemConfig = systemConfig;

        return (
            <CartContext.Provider value={{cart}}>
                <Snackbar />
                <div className="header">
                    <div className="container-lg header-row">

                        <div className="logo">
                            <a href="/dashboard">
                                <img src="/images/campfire-logo-bottom.png"
                                     className="img-fluid"
                                     alt="Campfire | Brewing &amp; Beer Garden | Aguascalientes Cerveza Artesanal"/></a>
                        </div>

                        <div className="social-icons">
                            <div className="tooltip-animated animate__animated animate__shakeX">Revisa tu pedido aquí &nbsp; <i className="fal fa-arrow-right" /></div>
                            <a href="#" onClick={this.showDrawer}><i className="fal fa-bars"/></a>
                        </div>
                    </div>
                </div>

                <div>
                <Drawer placement="right" width="270" open={this.state.drawerOpen} handler={false} onClose={this.hideDrawer}>
                    <Nav defaultActiveKey="/" className="flex-column">
                        <Nav.Link as={Link} to="/new" onClick={this.hideDrawer}>
                            <i className="fal fa-utensils fa-fw" /> Food & Drinks Menu
                        </Nav.Link>
                        <Nav.Link as={Link} to="/account/orders" onClick={this.hideDrawer}>
                            <i className="fal fa-shopping-bag fa-fw" /> Mis Pedidos
                        </Nav.Link>
                        <Nav.Link as={Link} to="/pay-bill" onClick={this.hideDrawer}>
                            <i className="fal fa-credit-card fa-fw" /> Pagar Cuenta
                        </Nav.Link>
                        {cart.getCustomerDetails() === null ? null :
                        <Nav.Link href="#" onClick={this.handleLogout}>
                            <i className="fal fa-sign-out fa-fw"/> Logout
                        </Nav.Link>
                        }

                        <div className="footer-items">
                            <Nav.Link href="tel:4492514915"><i className="fal fa-phone fa-fw"/></Nav.Link>
                            <Nav.Link href="/index.php#contact-form">
                                <i className="fal fa-envelope fa-fw"/>
                            </Nav.Link>
                            <Nav.Link href="https://campfire.mx" target="_blank">
                                <i className="fal fa-home-alt fa-fw" />
                            </Nav.Link>
                        </div>
                    </Nav>
                </Drawer>
                </div>

                <div id="page-wrapper" className="container-lg" style={{padding: 0}}>
                    <Route exact path="/" render={() => <Redirect to="/dashboard" />} />

                    <div className="page-content" id="page-content-wrapper">

                        <AnimatedSwitch/>
                    </div>
                </div>

                {systemOnline ? null :
                <SystemOfflineAlert />
                }
            </CartContext.Provider>

        );
    }
}

function AnimatedSwitch() {

    let location = useLocation()

    return (
        <TransitionGroup>
            <CSSTransition
                key={location.key}
                classNames="animatable"
                timeout={300}
            >
                <div>
                    <Switch>
                        <Route exact path="/dashboard" key="dashboard" component={Dashboard} />
                        <Route exact path="/pay-bill" key="pay-bill" component={() => <StripeInjectedComponent component={BillPayment}/>} />
                        <Route exact path="/new" key="new-order" component={OrderProductsMenu} />
                        <Route exact path="/checkout" key="checkout-page" component={OrderCheckout} />
                        <Route exact path="/account/orders" key="orders-list-page" component={AccountOrders} />
                        <Route component={NoMatch}/>
                    </Switch>
                </div>
            </CSSTransition>
        </TransitionGroup>
    )
}

const NoMatch = ({ location }) => (
    <div>
        <h3>The page you are looking for can not be found: <code>{location.pathname}</code></h3>
    </div>
)



export default App;

