import React from "react";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import {Redirect} from "react-router-dom";
import CartContext from "../../components/CartContext";
import ApiClient from "../../components/ApiClient";

class OrderConfirmation extends React.Component {

    static contextType = CartContext;

    state = {
        refreshPage: false,
    }

    close = (ev) => {

        this.context.cart && this.context.cart.clearCart();
        this.setState({refreshPage: true})
    }

    cancel = (ev) => {
        if (this.props.confirmed) {
            alert('Su pedido ya ha sido confirmado por el personal de Campfire y no se puede cancelar en línea. ' +
                'Llámenos al 449-251-4915 para cancelarlo. Gracias!');
            return;
        }

        const result = window.confirm('¿Estás seguro de que deseas cancelar tu pedido?');

        if (!result) {
            return;
        }

        // Enable loading
        this.setState({
            cancelling: true,
        })

        // Prepare data
        const {orderId} = this.props;

        // Send as AJAX
        ApiClient.patch({
            endpoint: window.API_BASE_URL + '/api/venue/1/order/' + orderId,
            data: {
                is_canceled: true
            }
        }).then((res) => {
            this.context.cart && this.context.cart.clearCart();
            this.setState({refreshPage: true}, () => window.location.reload())


        }, (err) => {
            this.context.cart && this.context.cart.clearCart();
            this.setState({refreshPage: true}, () => window.location.reload())

        });
    }

    render() {
        const { cancelling, refreshPage } = this.state;

        const {
            loading,
            waiting,
            confirmed,
        } = this.props;

        if (refreshPage) {
            return (
                <Redirect to="/new"/>
            )
        }

        let message = '';
        let note = null;

        if (loading) {
            message = 'Enviando el pedido';
        }

        if (waiting) {
            message = (<><i className="fas fa-check-circle text-success mr-2"/> Esperando confirmación</>);
            note = (
                <>
                    <span className="text-weight-bold marginB d-block size16 text-secondary">Espere un momento mientras confirmamos su pedido.</span>
                    <span className="size14">También recibirá una confirmación en su correo electrónico, por lo que puede cerrar esta ventana si lo desea.</span>
                </>
            )

            if (confirmed) {
                message = (<><i className="fas fa-check-circle text-success mr-2"/> ¡Orden confirmada!</>);

                note = (<span className="size20">{`Su pedido está listo en ${confirmed} minutos`}</span>);
            }
        }

        return (
            <Modal
                show={loading || waiting}
                centered
                backdrop="static"
                dialogClassName="confirmation-popup"
            >
                <Modal.Body>
                    <div className="screen-loading">
                        <h3>{message}</h3>
                        {confirmed ? null : <div><Spinner animation="border" variant="primary" /></div>}

                        <p className="loading-note">{note}</p>

                        {!waiting ? null :
                            <>
                            {confirmed ? null : <Button disabled={cancelling} variant="link" onClick={this.cancel}>Cancelar orden</Button>}
                            <Button variant="link" onClick={this.close}>Cerrar</Button>
                            </>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

export default OrderConfirmation;
