
import request from 'superagent';


import update from 'react-addons-update';

const defaultOptions = {
    endpoint: null,
    data: null,
    callback: null,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Cache-Control': 'no-cache,no-store,must-revalidate,max-age=-1'
    }
}

var ApiClient = {

    getPostRequestObject: function ( url, options ) {
        if ( !options ) {
            options = {};
        }
        options = update(defaultOptions, {$merge: options });


        return request.post(url)
            .set ( options.headers );
    },

    /**
     * Return a GET request object Using ES6 promises
     * @param type
     * @param options
     */
    get: function ( options ) {

        options = update(defaultOptions, {$merge: options });


        return request.get( options.endpoint )
            .accept('json')
            .type('json')
            .set ( options.headers )
            .query ( options.query );
    },

    post: function ( options, jwt ) {

        options = update(defaultOptions, {$merge: options });


        return request.post( options.endpoint )
            .accept('json')
            .type('json')
            .set ( options.headers )
            .query ( options.query )
            .send( options.data );
    },

    patch: function ( options, jwt ) {

        options = update(defaultOptions, {$merge: options });


        return request.patch( options.endpoint )
            .accept('json')
            .type('json')
            .set ( options.headers )
            .query ( options.query )
            .send( options.data );
    },

    put: function ( options ) {

        options = update(defaultOptions, {$merge: options });
        return request.put( options.endpoint )
            .accept('json')
            .type('json')
            .set ( options.headers )
            .query ( options.query )
            .send( options.data );
    },

    delete: function ( options ) {

        options = update(defaultOptions, {$merge: options });
        return request.delete( options.endpoint )
            .accept('json')
            .type('json')
            .set ( options.headers )
            .query ( options.query );
    },

    onError: function(err) {

        if ( err ) {

            var res = err.response ? err.response : [];

            var error = ''
                + ( res && res.error ? res.error : 'API error' );
            if ( res && res.body && res.body.error ) {
                error = res.body.error;
            }

            error = error.replace(/\n/g, '<br/>');
            window.alert(error);

            //Notifications.showNotific8(error, 'danger', { closeInSeconds: 6 } );
        }
    },

    /**
     * End ES6 functions
     */

    createPaymentIntent: data => {
        return window
          .fetch(`${window.API_BASE_URL}/api/venue/1/order/create-payment-intent`, {
              method: "POST",
              headers: {
                  "Content-Type": "application/json"
              },
              body: JSON.stringify(data)
          })
          .then(res => {
              if (res.status === 200) {
                  return res.json();
              } else {
                  return null;
              }
          })
          .then(data => {
              if (!data || data.error) {
                  console.log("API error:", {data});
                  throw new Error("PaymentIntent API Error");
              } else {
                  return data.client_secret;
              }
          });
    }
}



export default ApiClient;
