import * as React from "react";
import Tab from "react-bootstrap/Tab";
import ScrollMenu from 'react-horizontal-scrolling-menu';


// One item component
// selected prop will be passed
const MenuItem = ({text, selected}) => {
    return <div
        className={`menu-item ${selected ? 'active' : ''}`}
    >{text}</div>;
};

// All items component
// Important! add unique key
export const Menu = (list, selected) =>
    list.map(el => {
        const {name} = el;

        return <MenuItem text={name} key={name} selected={selected} />;
    });


class CategoryMenu extends React.Component {

    state = {
        selected: this.props.tabs && this.props.tabs[0] && this.props.tabs[0].name ? this.props.tabs[0].name : null,
    };

    constructor(props) {
        super(props);

        // call it again if items count changes
        this.menuItems = Menu(this.props.tabs, this.state.selected);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.menuItems = Menu(nextProps.tabs, this.state.selected);
    }

    onSelect = key => {
        this.setState({ selected: key }, () => {
            let topOffset = 0;

            const el = document.getElementById(`category-${key}`)
            const offset = function (el) {
                var rect = el.getBoundingClientRect(),
                    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
                    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
            }

            window.scrollTo({
                top: el ? offset(el).top - 180 : 0,
                behavior: 'smooth'
            })
        });
    }

    onNextArrow = () => {
        const {tabs} = this.props;

        // Get current tab key
        //const currentKey

        const nextTab = tabs && tabs[1] && tabs[1].name ? tabs[1].name : null

        this.onSelect(nextTab);
    }

    render() {
        const { selected } = this.state;

        // Create menu from items
        const menu = this.menuItems;

        return (
            <nav className="products-nav" role="tablist">
                <div className="container-lg">
                    <ScrollMenu
                        data={menu}
                        /*arrowLeft={ArrowLeft}
                        arrowRight={ArrowRight}*/
                        selected={selected}
                        onSelect={this.onSelect}
                        hideArrows={true}
                        alignCenter={false}
                        scrollToSelected={true}
                    />
                    {/*<div className="next-arrow" onClick={this.onNextArrow}><i className="fal fa-chevron-right"></i></div>*/}
                </div>
            </nav>
        )
    }
}

export default CategoryMenu;
