import React from 'react';
import {CardCvcElement, CardElement, CardExpiryElement, CardNumberElement} from '@stripe/react-stripe-js';
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const options = {
  style: {
    base: {
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '1.5',
      color: '#495057',

      transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
      "::placeholder": {
        color: '#afafaf',
      }
    }
  }
};

class StripeForm extends React.Component {
  componentDidMount() {

  }

  render() {
    const { onChange } = this.props;

    return (
        <>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Card number *</Form.Label>
                <CardNumberElement options={options} onChange={onChange} />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group style={{width: 100, display: 'inline-block', marginRight: 20}}>
                <Form.Label>Expiry *</Form.Label>
                <CardExpiryElement options={options} onChange={onChange} />
              </Form.Group>

              <Form.Group style={{width: 80, display: 'inline-block'}}>
                <Form.Label>CVC *</Form.Label>
                <CardCvcElement options={options} onChange={onChange} />
              </Form.Group>
            </Col>
          </Row>
        </>
    )
  }
}

export default StripeForm;
