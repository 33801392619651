import AppState from "../stores/AppState";
import _ from "lodash";
import md5 from "md5";

class Cart {

    systemOnline = true
    systemConfig = {
        ordering_online_status: 'on',
        ordering_online_delivery_fee: 30,
    }

    data = {
        items: [],
        order: {},
    }

    constructor(cart) {
        if (cart && cart.items) {
            this.setItems(cart.items);
        }

        if (cart && cart.order) {
            this.setOrder(cart.order);
        }
    }

    getCustomerDetails() {
        const emailAddress = localStorage.getItem('email_address');
        if (!emailAddress) {
            return null;
        }

        let formData = localStorage.getItem('form_details');
        if (!formData) {
            formData = "{}";
        }

        formData = JSON.parse(formData);

        return {
            email: emailAddress,
            first_name: formData.first_name || '',
            last_name: formData.last_name || '',
            phone: formData.phone || '',
            postcode: formData.postcode || '',
            address: formData.address || '',
            address_details: formData.address_details || '',
        }
    }

    setCustomerDetails(details) {
        const emailAddress = localStorage.getItem('email_address');
        if (!emailAddress) {
            return null;
        }

        localStorage.setItem('form_details', JSON.stringify(details));
    }

    setOrderField(field,value) {
        let {order} = this.data;
        if (!order) {
            order = {};
        }

        order[field] = value;

        this.setOrder(order)
    }

    setOrder(order) {
        this.data.order = order;

        this._updateStore();
    }

    getOrder() {
        return this.data.order;
    }

    setItems(cart) {
        this.data.items = cart;

        this._updateStore();
    }

    getItems() {
        return _.clone(this.data.items);
    }

    getProduct(name) {
        return this.getItems() ? _.find(this.getItems(), {name}) : null;
    }

    getRowIndex(name) {
        const cart = this.getItems();

        return _.findIndex(cart, {name});
    }

    getTotalItems() {
        return _.sumBy(this.getItems(), (o) => o.price * o.qty);
    }

    getTotal() {
        return this.getTotalItems() + this.getDelivery() - this.getDiscount();
    }

    getDiscount() {
        const {order_type} = this.getOrder();

        let systemPickupDiscount = this.getSystemConfig('ordering_online_pickup_discount');
        systemPickupDiscount = !systemPickupDiscount ? 0 : systemPickupDiscount / 100;
        let totalDiscount = 0;

        // Calculate discount
        if (order_type === 'Pick Up') {
            //totalDiscount = this.getTotalItems() * 0.1;

            // Calculate % discount but not for special price!
            totalDiscount = _.sumBy(this.getItems(), p => {

                let discount = 0;
                // Has special price and smaller than base price? Apply difference as discount
                if (p.special_price && parseInt(p.special_price) < parseInt(p.base_price)) {
                    discount = (parseInt(p.base_price) - parseInt(p.special_price)) * p.qty;

                } else {
                    // Has special price equal with base price? Don't apply any discount
                    if (p.special_price && parseInt(p.special_price) === parseInt(p.base_price)) {
                        discount = 0;
                    } else {
                       discount = (p.base_price * systemPickupDiscount) * p.qty;
                    }
                }

                // Now apply discount to extras too
                if (p.extras && p.extras.length) {
                    discount += (_.sumBy(p.extras, (e) => parseInt(e.price)) * systemPickupDiscount) * p.qty;
                }

                return discount;
            });

            // Calculate discount by special price
            /*totalDiscount = _.sumBy(this.getItems(), p => {

                if (!p.special_price) {
                    return 0;
                }

                return (parseInt(p.base_price) - parseInt(p.special_price)) * p.qty;
            });*/
        }

        return totalDiscount;
    }

    getDelivery() {
        const {order_type} = this.getOrder();

        let delivery = 0;

        // Calculate discount
        if (order_type === 'Delivery') {
            delivery = parseInt(this.getSystemConfig('ordering_online_delivery_fee'));
        }

        return delivery;
    }

    updateQuantityByRowId(rowId, product, qty) {
        const items = this.getItems();

        const addedIndex = _.findIndex(items, {rowId});

        if (-1 === addedIndex && parseInt(qty) > 0) {
            // Add to cart
            items.push({
                rowId: rowId,
                name: product.name,
                price: product.price,
                base_price: product.base_price,
                special_price: product.special_price,
                qty: parseInt(qty),
                note: '',
                extras: product.extras,
                parent: product.parent,
                variant: product.variant,
                product_id: product.product_id || null,
            })

        } else {
            qty = parseInt(qty)

            if (qty === 0) {
                _.pullAt(items, [addedIndex]);

            } else if (qty === -1 && parseInt(items[addedIndex].qty) <= 1) {

                _.pullAt(items, [addedIndex]);

            } else {

                items[addedIndex].qty = items[addedIndex].qty + qty;
            }
        }

        this.setItems(items);

        this._updateStore();
    }

    updateQuantity(product, qty, variant) {
        const { items } = this.data;

        let productName = product.name;
        if (variant) {
            productName += ' - ' + variant;
        }

        const addedIndex = this.getRowIndex(productName);

        if (-1 === addedIndex && qty > 0) {

            // Is variant, has parent product?
            let parent = '';
            if (variant) {
                parent = product.name;
            }

            // Generate row ID
            let rowId = md5(productName)

            // Add to cart
            items.push({
                rowId: rowId,
                name: productName,
                price: product.price,
                qty: qty,
                note: '',
                variant,
                parent,
                product_id: product.product_id || null,
            })

        } else {

            if (qty > 0) {
                items[addedIndex].qty = qty;

            } else {
                _.pullAt(items, [addedIndex]);
            }
        }

        this.setItems(items);

        this._updateStore();
    }

    updateNote(rowId, product, note) {
        const items = this.getItems();

        const addedIndex = _.findIndex(items, {rowId});

        if (-1 !== addedIndex) {
            items[addedIndex].note = note;

            this.setItems(items);

            this._updateStore();
        }
    }

    removeProduct(name) {
        const { items } = this.data;
        const addedIndex = this.getRowIndex(name);

        _.pullAt(items, [addedIndex]);
        this.setItems(items);

        this._updateStore();
    }

    clearCart() {
        this.setItems([]);
        this.setOrder({})

        this._updateStore();
    }

    getSystemConfig(name) {
        return this.systemConfig && this.systemConfig[name];
    }

    _updateStore() {
        AppState.set('cart', this.data)
    }
}

export default Cart;
